import { Grid } from '@material-ui/core';
import { Itinerary, UploadedImage } from '@tripr/common';
import React from 'react';
import { Field } from 'react-final-form';
import { FormList } from '../../common/FormList';
import { MyTextField } from '../../common/forms/TextField';
import { MultiImageUploader } from '../../common/MultiImageUploader';
import { ImagesApi } from '../../../api/ImagesApi';

const uploadAccommodationImg = async (file: File): Promise<UploadedImage> => new ImagesApi().uploadImage('itinerary', file);

export const ItineraryList: React.FC<{ name: string }> = ({ name }) => (
  <FormList<Itinerary>
    className="itinerary"
    name={name}
    newValue={() => ({ day: 0, headline: '', description: '', photos: [] })}
    render={p => (
      <Grid container spacing={2}>
        <Field component={MyTextField} name={`${p.parentName}.day`} label="Day" xs={2} variant={'filled'} type={'number'} />
        <Field component={MyTextField} name={`${p.parentName}.headline`} label="Headline" xs={10} multiline variant={'filled'} />
        <Field component={MyTextField} name={`${p.parentName}.description`} label="Description" xs={12} multiline variant={'filled'} />
        <MultiImageUploader name={`${p.parentName}.photos`} uploader={uploadAccommodationImg} size={[240, 240]} />
      </Grid>
    )}
  />
);
